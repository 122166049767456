import React from 'react';
export function Comp_Auto_Generate_Fee(props){
    return(<div className="modal fade bd-example-modal-xl"  id="divAutoGenerateFeeAuth" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Generate Fee</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmAutoGenerateFeeAuth" name="frmAutoGenerateFeeAuth">
       
        <div className="modal-body">
        <div className="row">
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                              <input type="hidden" name="res_func" id="res_func" value="okSuccAutoGenerateFee" />
                              <input type="hidden" name="curl_type" id="curl_type" value="autoGenerateFeeAuth" />
                              <input type="hidden" name="m_type" id="m_type" value="autoGenerateFeeAuth" />
                              <input type="hidden" name="type_id" id="type_id" value="2" />
                              <input type="hidden" name="upper_id" id="upper_id" value={btoa(props.app_user_id)} />
                              <div className="card-body">
                                  <div className="row">
                                  <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Branch *</label>
                                              <div className="input-group input-group-sm">
                                                  <select id="fee_gen_branch" name="fee_gen_branch" className="form-control form-control-sm required">
                                                  {props.lsBranches.length>1?<option value={btoa("0")}>-All-</option>:''}
                {props.lsBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                  
                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Session Year *</label>
                                              <div className="input-group input-group-sm">
                                                  <select id="fee_gen_session_year" name="fee_gen_session_year" className="form-control form-control-sm required">
                                                  <option value={btoa("0")}>-Session Year-</option>
                {props.lsSessionYear.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["session_name"]}</option>
                })}
                                                  </select>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                              <label for="exampleInputEmail1">Class</label>
                                              <div className="input-group input-group-sm">
                                              <select id="fee_gen_class_name" name="fee_gen_class_name" className="form-control form-control-sm">
                <option value={btoa("0")}>-Classes-</option>
                {props.lsClassMaster.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["class_name"]}</option>
                })}

               </select>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-lg-12 col-md-12">
                                          <div className="form-group">
                                            <div className="input-group input-group-sm">
                                              <input id="chkConfirmFeeGen" name="chkConfirmFeeGen" className="from-control" type="checkbox"/><label for="chkConfirmFeeGen">Confirm Before Process *</label>        
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Generate Fee</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Comp_Auto_Generate_Fee;