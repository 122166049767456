import $, { extend } from 'jquery';
import 'react-bootstrap/dropdown';
import Popper from 'popper.js';
//import {Popper} from '@popperjs/core';
import React from 'react';

import "jquery-ui-bundle/jquery-ui";
import "jquery-ui-bundle/jquery-ui.css";

import 'react-bootstrap/modal';

import 'react-bootstrap/tooltip';

import {UserToken,getUserObjData,getAccessFunctions,getCol3} from '../function_lib/userToken';
import {CallMapMethod,AjaxCallMethod,CallMapMethod_CBack,validateNumberLength,TableDatePicker,
  btnSetColor_CBack} from '../function_lib/common_lib';

import listData from '../function_lib/customeData';

import {Custom_Alert,Alert_Content} from '../components/custom_alert';

import Header from './header';
import Footer from './footer';


import defaultPic from '../assets/images/9.png';

import Comp_Print_Report from '../components/comp_print_report';
import Comp_Stu_Invoice_Breakdown from '../components/comp_stu_invoice_breakdown';

import Comp_Class_Fee_Master from '../components/comp_class_fee_master';
import Comp_Edit_Student_Basic from '../components/comp_edit_student_basic';
import Comp_Edit_Student_Family from '../components/comp_edit_student_family';
import Comp_Edit_Student_Auth from '../components/comp_edit_student_auth';



let loginUser="";
let userObject="";
let companyName="";
let clsStudentAdmission;

let rf="0";
window.Popper = Popper;

let genderList=["M.","F."];
let castCategoryList=["GEN","OBC","SC","ST"];

let _totalFee=0;
let _paidFee=0;
let _dueAmount=0;
let _otherDues=0;
let _totalDues=0;

let _objAccess={};
class View_Student_Wise_Due extends React.Component{
  forceUpdateHandler(){
    this.forceUpdate();
  };

    constructor(props){
        super(props);
        if(localStorage.getItem("userData")!=null){
          userObject= getUserObjData();
          //alert(JSON.stringify(userObject));
          loginUser=userObject[0].first_name;
          companyName=userObject[0].first_name;
      }
      _objAccess=getAccessFunctions();

      this.state={stListBranches:[] ,stListTransactions:[],stListSessionYear:[],stListClassMaster:[],
        stListFeeCategory:[],stPrintTransaction:[],stPtrHeads:[],stPtrCols:[],stPtrTotals:[],
        stSelectItem:[],stStudentInvoice:[],stStudentBreakups:[],
        stApplyDelete:false
      };
          this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
          clsStudentAdmission=this;
      }
      setPrintHeaders(listData,transType="outstanding"){
        let ptrColumnHeader=[];
        let ptrColumnName=[];
        let totals=[];
       
        if(transType==="outstanding"){
          //alert("i m in head "+JSON.stringify(listData));
           ptrColumnHeader=["Scholar No.","Student Name","Father's Name","Session Year","Class Name","Total Fee","Other Due","Paid","Total Due"]; 
           ptrColumnName=["scholar_no","student_name","father_name","session_name","class_name","net_amount","other_due","paid_fee","total_due"]; 
          
  totals=[{"col":"net_amount","col_index":6,"total_value":0},
  {"col":"other_due","col_index":7,"total_value":0},
  {"col":"paid_fee","col_index":8,"total_value":0},
  {"col":"total_due","col_index":9,"total_value":0}
];
        }else if(transType==="stu_trans"){

          ptrColumnHeader=["Date","Scholar No.","Student Name","Father's Name","Session Year","Ledger","Receipt No","Debit","Credit","Payment Mode"]; 
          ptrColumnName=["fmt_date","scholar_no","student_name","father_name","session_name","acc_ledger","receipt_no","debit","credit","payment_mode"];

          totals=[{"col":"debit","col_index":8,"total_value":0},
            {"col":"credit","col_index":9,"total_value":0}
            // {"col":"Net Amount","col_index":8,"total_value":0}
          ];
        }

        //set state
        clsStudentAdmission.setState({stPrintTransaction:listData,
          stPtrHeads:ptrColumnHeader,stPtrCols:ptrColumnName,stPtrTotals:totals
        },()=>{
          clsStudentAdmission.forceUpdateHandler();
        });
      }

      // getStudentWiseTrans(item)

      
onStudentInvBreakdown(item,enableDelete=false){

  let map=[];
  map.push({name:"curl_type",value:"getStudentInvoice"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"user_type",value:userObject[0].type});
  map.push({name:"stu_id",value:btoa(item.stu_admis_id)});
  map.push({name:"class_id",value:btoa(item.class_id)});
  map.push({name:"session_year_id",value:btoa(item.session_year_id)});
  //alert($("#ser_session_year").val());
  //map.push({name:"upper_id",value:userObject[0].upper_id});
  map["res_func"]="callBackrrr";
  map["curl_type"]="getStudentInvoice";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
    if(data!=="0"){
      let lsData=JSON.parse(data);
      //alert(JSON.stringify(lsData.invoice_breakups));
      clsStudentAdmission.setState({stSelectItem:item,stStudentInvoice:lsData.student_invoice,
        stStudentBreakups:lsData.invoice_breakups,stApplyDelete:enableDelete},()=>{
        clsStudentAdmission.forceUpdateHandler();
      })
    }
  })
}

      
      getStudentWiseTrans(item,isCurrentYear=true){
         //alert(JSON.stringify(item));
        // return;
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getFeeTransactions"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"stu_admis_id",value:btoa(item.stu_admis_id)});

        if(isCurrentYear===true){
          map.push({name:"stu_inv_id",value:btoa(item.id)});
        }
        
        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getFeeTransactions";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
              let listData=JSON.parse(data);
              clsStudentAdmission.setPrintHeaders(listData,"stu_trans");
              }
        });
      }



      getStudentFeeTransaction(status="0"){
        //child component use this method loan_document_upload_comp
        var map=[];
        map.push({name:"curl_type",value:"getStudentWiseDue"});
        map.push({name:"res_func",value:"callBackrrr"});
        map.push({name:"local_token",value:userObject[0].app_user_id});
        map.push({name:"user_type",value:userObject[0].type});
        map.push({name:"ser_session_year",value:$("#ser_session_year").val()});
        map.push({name:"ser_class_name",value:$("#ser_class_name").val()});
        map.push({name:"ser_text",value:$("#ser_text").val()});
        map.push({name:"ser_branches",value:$("#ser_branches").val()});
        map.push({name:"ser_stu_status",value:$("#ser_stu_status").val()});
        map.push({name:"ser_section",value:$("#ser_section").val()});
        // map.push({name:"ser_type",value:$("#ser_type").val()});
        // map.push({name:"ser_from_date",value:$("#ser_from_date").val()});
        // map.push({name:"ser_to_date",value:$("#ser_to_date").val()});

        //alert($("#ser_session_year").val());
        //map.push({name:"upper_id",value:userObject[0].upper_id});
        map["res_func"]="callBackrrr";
        map["curl_type"]="getStudentWiseDue";
        CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
          //alert(data);
            if(data!=="0" && data!==""){
                let listData=JSON.parse(data);
                var strData="";
                let netStudentDue=0;
                for(let i=0;i<listData.length;i++){
                  
_totalFee=0;
_paidFee=0;
_dueAmount=0;
_otherDues=0;
_totalDues=0;
                    // alert(listData[i]["paid_fee"]);
                    // break;
                  if(listData[i]["paid_fee"]==="" || listData[i]["paid_fee"]===null){
                    listData[i]["paid_fee"]=0;
                  }
                  if(listData[i]["other_due"]==="" || listData[i]["other_due"]===null){
                    listData[i]["other_due"]=0;
                  }
                  netStudentDue=0;

                  netStudentDue=parseFloat(listData[i]["inv_net_amount"])+parseFloat(listData[i]["other_due"])-parseFloat(listData[i]["paid_fee"]);
                  listData[i]["total_due"]=netStudentDue;
                
                }
                //alert(JSON.stringify(listData));
               
                
              
                $(".outstanding_rpt").html(listData.length+" records found ");
                
                clsStudentAdmission.setState({stListTransactions:listData},()=>{
                  //window.getPagination("#tbData");
                  clsStudentAdmission.forceUpdateHandler();
                  //RespBkMthdMapp(clsUserMgmt,"_btnShareOption","");
                });
              }
        });
      }
  


getMasters(){
  var map=[];
  map.push({name:"curl_type",value:"getMastersRequest"});
  map.push({name:"res_func",value:"callBackrrr"});
  map.push({name:"local_token",value:userObject[0].app_user_id});
  map.push({name:"branch_acc",value:getCol3()});
  
  map["res_func"]="callBackrrr";
  map["curl_type"]="getMastersRequest";
  CallMapMethod_CBack(clsStudentAdmission,map,function(data,msg){
    //alert(data);
      if(data!=="0" && data!==""){
          let listData=JSON.parse(data);
          var strData="";
          //alert(JSON.stringify(listData.master_balance));
          clsStudentAdmission.setState({
            stListSessionYear:listData.ls_session_year,
            stListClassMaster:listData.ls_class_master,
          stListFeeCategory:listData.ls_fee_category,
          stListBranches:listData.ls_branches},()=>{
            clsStudentAdmission.forceUpdateHandler();
          })
        }
  });
}
          
      
componentDidMount(){
    
  AjaxCallMethod(this,"okSuccCNOT");
$("#app_user_id").val(userObject[0].app_user_id);


this.clsBtnBreakUpsClick =new listData("_btnInvoiceBreakdown", "hellow");     

$('#divStuInvBreakdown').on('hidden.bs.modal', function () {
  //window.location.reload();
  //clsStudentAdmission.setState({stStudentBreakups:[]});
  clsStudentAdmission.getStudentFeeTransaction();
  //this.clsBtnBreakUpsClick =new listData("_btnInvoiceBreakdown", "hellow");     
  // do something…
});

//this.getStudentFeeTransaction();
this.getMasters();

}


      render(){
          return(<div>
<Header/>
<div className="content">
<div className="container-fluid mt-4">
<div className="row content12 mt-2 mb-2">
{/* for now [row]it is unused */}
{/* starting of cols */}

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_session_year" name="ser_session_year" className="form-control form-control-sm">
                <option value={"0"}>-Session Year-</option>
                {this.state.stListSessionYear.map((item,index)=>{
                  return <option value={item["id"]}>{item["session_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_branches" name="ser_branches" className="form-control form-control-sm">
               {this.state.stListBranches.length>1?<option value={btoa("0")}>-All-</option>:''}
                {this.state.stListBranches.map((item,index)=>{
                  return <option value={btoa(item["id"])}>{item["branch_name"]}</option>
                })}
               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-1 col-md-1 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_class_name" name="ser_class_name" className="form-control form-control-sm">
                <option value={"0"}>-Classes-</option>
                {this.state.stListClassMaster.map((item,index)=>{
                  return <option value={item["id"]}>{item["class_name"]}</option>
                })}

               </select>
             </div>
         
         </div>
</div>


<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_section" name="ser_section" className="form-control form-control-sm">
                <option value={"0"}>-Section-</option>
                <option value={"A"}>A</option>
                <option value={"B"}>B</option>
                <option value={"C"}>C</option>
                <option value={"D"}>D</option>
               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-4 float-right'>
<div className="form-group">
         <div className="input-group">
               <select id="ser_stu_status" name="ser_stu_status" className="form-control form-control-sm">
                <option value={"0"}>-Stu. Status-</option>
                <option value={"Active"}>Active</option>
                <option value={"Terminated"}>Terminated</option>
               </select>
             </div>
         
         </div>
</div>

<div className='col-lg-2 col-md-2 col-sm-2 float-right'>
<div className="form-group">
         <div className="input-group">
               <input autoComplete='off' type="text" id="ser_text" name="ser_text" className="_setSearchKeyUp form-control form-control-sm" placeholder='Student Name/Scholar No.' />
             </div>
         
         </div>

</div>
<div className="col-lg-4 col-md-4 col-sm-4 vcenter-item1 float-right">
<div className="input-group">
               
               <div className="input-group-prepend">
               {/* <div className='col-1211 '> */}
                 <div onClick={()=>this.getStudentFeeTransaction()}  style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Search</div>
                 <div onClick={()=>window.location.reload()} style={{marginRight:"10px"}} className="btn btn-sm btn-danger">Reset</div>
                 
                 <button style={{marginRight:"10px"}} onClick={()=>this.setPrintHeaders(this.state.stListTransactions)} class="btn btn-sm btn-primary ml-2"  type="button" data-toggle="modal" data-target="#divPrintReport" data-bs-toggle="modal" data-bs-target="#divPrintReport">
        Export
      </button>

                 {/* </div> */}
               </div>
             </div>
</div>
{/* end of cols */}

    </div>    
<div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
         
      <div class="card" id="exp_body">
              {/* <!-- /.card-header --> */}
           <div class="card-header bg-cyan">
                <h3 class="card-title "><i class="fa fa-arrow-circle-right"></i> <b>Student Wise Due</b></h3>
                <div className="outstanding_rpt float-left px-10"></div>
                {/* <a className="btn btn-sm btn-danger float-right" href="../student_admission">Add New Student</a> */}
              </div>
                <table id="tbData" class="dtgrid dtgrid table table-bordered table-hover text-sm text-nowrap1 table-responsive">
                  <thead>
                    <tr class="border">
                      <th>S.No.</th>
                      <th>Scholar No</th>
                      <th>Student Name</th>
                      <th>Father's Name</th>
                      <th>Session Year</th>
                      <th>Class Name</th>
                      <th>Section</th>
                      <th>Total Fee</th>
                      <th>Other Due</th>
                      <th>Paid</th>
                      <th>Total Due</th>
                      <th>Is Active</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {(this.state.stListTransactions.map((item,index)=>{
                    
                  
                    if (index===0){
                      _totalFee=0;
                      _paidFee=0;

                      _otherDues=0;
                      _totalDues=0;
                    }
                    _totalFee+=parseFloat(item.inv_net_amount);
                    _paidFee+=parseFloat(item.paid_fee);
                   
                    _otherDues+=parseFloat(item.other_due);
                    _totalDues+=parseFloat(item.total_due);

                       return(<tr>
                        <td>{index+1}</td>
                        <td>{item.scholar_no}</td>
                        <td>{item.student_name}</td>
                        <td>{item.father_name}</td>
                        <td>{item.session_name}</td>  
                        <td>{item.class_name}</td>
                        <td>{item.stu_section}</td>  
                        <td>Rs.{item.inv_net_amount}</td>
                        <td>Rs.{item.other_due}</td>
                    <td>Rs.{item.paid_fee}</td>    
                  <td>Rs.{item.total_due}</td>
                  <td>{item.is_active}</td>
                  <td>
                  <div class="btn-group">
                    <button type="button" class="btn btn-default dropdown-toggle" data-toggle="dropdown" area-expanded="false">
                      Action
                    </button>
                    <div class="dropdown-menu">
                   
                    <a class="dropdown-item" onClick={()=>this.getStudentWiseTrans(item,true)}  data-toggle="modal" data-target="#divPrintReport" href="javascript:void(0)">Current Year Trans.</a>
                     <a class="dropdown-item" onClick={()=>this.getStudentWiseTrans(item,false)}  data-toggle="modal" data-target="#divPrintReport" href="javascript:void(0)">Till Date Trans.</a>
                     {item.stu_invoice_id!=="" && item.stu_invoice_id!==null?<a class="dropdown-item" onClick={()=>this.onStudentInvBreakdown(item)} data-toggle="modal" data-target="#divStuInvBreakdown" href="javascript:void(0)">Update Fee</a>:''}
                     {typeof _objAccess.can_delete!=="undefined" && (item.paid_fee==="0" || item.paid_fee===0) && item.stu_invoice_id!=="" && item.stu_invoice_id!==null?<a class="dropdown-item red" onClick={()=>this.onStudentInvBreakdown(item,true)} data-toggle="modal" data-target="#divStuInvBreakdown" href="javascript:void(0)">Delete Fee Invoice</a>:''}
                    {/* <a class="dropdown-item" onClick={()=>this.onStudentDetailsEdit(item)} data-toggle="modal" data-target="#divEditStudentAuth" href="javascript:void(0)">View/Edit Authorization</a> */}
                    </div>
                  </div>
                       </td>
                    </tr>)
                  }))}
                  <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    
                    <td><b>{_totalFee}</b></td>
                    <td><b>{_otherDues}</b></td>
                    <td><b>{_paidFee}</b></td>
                    <td><b>{_totalDues}</b></td>
                    <td></td>
                    <td></td>

                  </tr>
                  </tbody>
                </table>
                <div class='pagination-container' >
				<nav>
				  <ul class="pagination">
            
            <li data-page="prev" >
								     <span> {"<"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  
        <li data-page="next" id="prev">
								       <span> {">"} <span class="sr-only">(window.current)</span></span>
								    </li>
				  </ul>
				</nav>
			</div>
              {/* <!-- /.card-body --> */}
            </div>
      </div>
</div>
</div>
</div>
<Alert_Content/>
<Footer/>
<Comp_Print_Report 
ptrColumnHeader={this.state.stPtrHeads} 
ptrColumnName={this.state.stPtrCols} 
lsTabelData={this.state.stPrintTransaction}

totals={this.state.stPtrTotals}

ptrTitle={"Student Wise Fee/Dues"}
 logUser={userObject}
/>

<Comp_Stu_Invoice_Breakdown lsSessionYear={this.state.stListSessionYear} 
    lsClassMaster={this.state.stListClassMaster}
    lsFeeCategory={this.state.stListFeeCategory} 
    lsAccLedger={this.state.stListAccLedger} 
    lsItem={this.state.stSelectItem}
    lsStudentInvoice={this.state.stStudentInvoice}
    lsInvoiceBreakups={this.state.stStudentBreakups}
    lsFeeBreakups={[]}
    applyDelete={this.state.stApplyDelete}
    childBtnBreakups={this.clsBtnBreakUpsClick}
     app_user_id={userObject[0].app_user_id}/>
   
    {/* <Comp_Class_Fee_Master lsSessionYear={this.state.stListSessionYear} lsClassMaster={this.state.stListClassMaster} lsFeeCategory={this.state.stListFeeCategory} app_user_id={userObject[0].app_user_id} /> */}
          </div>)
      }
}
export default View_Student_Wise_Due;