import React,{useEffect} from 'react';
import {TableDatePicker} from '../function_lib/common_lib';
export function Pay_Old_Dues(props){

  let listPendingDue=[{"debit":0,"credit":0,"dues":0}]
    useEffect(() => {
      let listPendingDue=[{"debit":0,"credit":0,"dues":0}] 
    },[])

    let vDebit=0;
    let vCredit=0;
    for(let i=0;i<props.stuOldDues.length;i++){
        if(props.stuOldDues[i]["type"]==="Credit"){
            vCredit+=parseFloat(props.stuOldDues[i]["value"]);
        }else if(props.stuOldDues[i]["type"]==="Debit"){
            vDebit+=parseFloat(props.stuOldDues[i]["value"]);
        }
    }
    listPendingDue[0]["debit"]=vDebit;
    listPendingDue[0]["credit"]=vCredit;
    listPendingDue[0]["dues"]=vDebit-vCredit;
  //alert(JSON.stringify(props.paymentBreakdown));
    return(<div className="modal fade bd-example-modal-xl"  id="divPayOldDues" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div className="modal-dialog modal-xl111" role="document">
      <div className="modal-content">
      <div className="modal-header bg-primary">
          <h5 className="modal-title text-white" id="exampleModalLabel">Pay Opening Dues</h5>
          <button type="button" className="close text-white" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true" className="text-white">&times;</span>
          </button>
        </div>
        <form id="frmPayOldDues" name="frmPayOldDues">
        <input type="hidden" name="res_func" id="res_func" value="oldDues_okSucc" />
                <input type="hidden" name="curl_type" id="curl_type" value="submitOldDue" />
                <input type="hidden" name="m_type" id="m_type" value="submitOldDue" />
                <input type="hidden" name="pod_stu_admis_id" id="pod_stu_admis_id" value={props.studentId}/>
                <input type="hidden" name="pod_acc_inf_id" id="pod_acc_inf_id" value={props.app_user_id}/>

        <div className="modal-body">
        <div className="row">
            <div className="col-md-12">
                <table class="table table-bordered table-sm">
                    <tr>
                    <td>Debit</td>
                    <td>Credit</td>
                    <td>Pending Due</td>
                      </tr>
                      {listPendingDue.map((item,index)=>{
                        return <tr>
                            <td>{"Rs."+item["debit"]}</td>
                            <td>{"Rs."+item["credit"]}</td>
                            <td>{"Rs."+item["dues"]}</td>
                        </tr>
                      })}  
                </table>

            </div>
                  {/* <!-- left column --> */}
                  <div className="col-md-12">
                      <div className="card card-danger">
                             
                              <div className="card-body">
                                  <div className="row">
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Date</label>
                                            <div className="input-group input-group-sm">
                                            <TableDatePicker refName="pod_date" />
                                            </div>
                                        </div>
                                  </div>
                                  <div className="col-lg-6 col-md-6">
                                        <div className="form-group form-group-sm row">
                                            <label for="exampleInputEmail1">Receipt No.</label>
                                            <div className="input-group input-group-sm">
                                            <input className="form-control required" type="number" id="pod_receipt_no" name="pod_receipt_no"/>
                                            </div>
                                        </div>
                                  </div>
                                      <div className="col-lg-12 col-md-12">
                                      <div class="form-group">
                                            <label for="exampleInputEmail1">Accounts Ledger</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="pod_accounts_ledger_id" name="pod_accounts_ledger_id" class="form-control" style={{"width":"100%"}}>
                                                    <option value="0">-Select-</option>
                                                       <option selected value="5">Opening Dues</option>
                                                    </select>
                                            </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 col-md-12">
                                      <div class="form-group">
                                            <label for="exampleInputEmail1">Transaction</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="pod_type" name="pod_type" class="form-control" style={{"width":"100%"}}>
                                                    <option  value="0">-Select-</option>
                                                       <option selected value="Credit">Credit</option>
                                                    </select>
                                            </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-12 col-md-12">
                                      <div class="form-group">
                                            <label for="exampleInputEmail1">Paid Amount</label>
                                            <div class="input-group input-group-sm">
                                            <input type="number" id="pod_value" name="pod_value" required_mg="Paid Amount" class="form-control form-control-sm" />  
                                            </div>
                                        </div>
                                      </div>


                                      <div className="col-lg-12 col-md-12">
                                      <div class="form-group">
                                            <label for="exampleInputEmail1">Payment Mode</label>
                                            <div class="input-group input-group-sm">
                                                    <select id="pod_payment_mode" name="pod_payment_mode" class="form-control" style={{"width":"100%"}}>
                                                    <option  value="0">-Select-</option>
                                                    <option  value="Cash">Cash</option>
                                                    <option  value="Cheque">Cheque</option>
                                                    <option  value="Bank Transfer">Bank Transfer</option>
                                                    <option  value="UPI">UPI</option>
                                                    <option  value="Credit Note">Credit Note</option>
                                                    <option  value="Debit Note">Debit Note</option>
                                                    </select>
                                            </div>
                                        </div>
                                      </div>
                                     
                                     
                                    
                                     
                                  </div>
                                
                                  <div className="col-md-6 respSSL"></div>
                                  <div id="testing2dd2222"></div>
                              </div>
                          
                      </div>
                  </div>
              </div>
        </div>
        <div className="modal-footer">
        <div id="getReponse" style={{color:"red"}}></div>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
          <button type="button" className="btn _btnSetColor btn-primary">Submit</button>
        </div>
        </form>
      </div>
      </div>
      </div>)
}
export default Pay_Old_Dues;